import "./index.css";
import "styles/App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContext } from "context/auth/authentication";
import React, { useEffect, useContext } from "react";
import Login from "pages/login";
import MyLayout from "components/layout/MyLayout";
import EmployeeType from "pages/master/employeeType/EmployeeType";
import EmployeeLeavePolicy from "pages/master/employeeLeavePolicy/EmployeeLeavePolicy";
import EmployeeDesignation from "pages/master/employeeDesignation/EmployeeDesignation";
import HolidaySetup from "pages/configuration/holidaySetup/HolidaySetup";
import AssetManagement from "pages/master/assetType/AssetManagement";
import EmployeeDepartemnt from "pages/master/employeeDepartment/EmployeeDepartment";
import EmployeeShift from "pages/configuration/employeeShift/EmployeeShift";
import CompanyProfilePage from "pages/admin/organization/CompanyProfile";
import EmployeeWeekOff from "pages/configuration/employeeWeekOff/EmployeeWeekOff";
import EmployeeList from "pages/employee/employeeList/EmployeeList";
import AddEmployeeList from "pages/employee/employeeList/addEmployeeList";
import AttendanceLog from "pages/attendance/attendanceLog/AttendanceLog";
import PageUnderConstruction from "pages/pageUnderConstruction"; // coming soon pages
import Dashboard from "pages/dashboard/Dashboard";
import Reimbursement from "pages/master/reimbursementType/Reimbursement";
import ForgotPassword from "pages/forgotPassword/ForgotPassword";
import OtpVerification from "pages/forgotPassword/OtpVerification";
import ResetPassword from "pages/forgotPassword/ResetPassword";
import PasswordSuccess from "pages/forgotPassword/PasswordSuccess";
import SalaryDeductions from "pages/configuration/salaryDeductions/SalaryDeductions";
import EmployeeGroup from "pages/master/employeegroup/EmployeeGroup";
import AssetCategory from "pages/master/assetCategory/AssetCategory";
import AddEmployeeLeavePolicy from "pages/master/employeeLeavePolicy/AddEmployeeLeavePolicy";
import AssetSubCategory from "pages/master/assetSubCategory/AssetSubCategory";
import AddEmployeeShift from "pages/configuration/employeeShift/AddEmployeeShift";
import AddEditSalaryDeduction from "pages/configuration/salaryDeductions/AddEditSalaryDeduction";
import AddHolidaySetup from "pages/configuration/holidaySetup/AddHolidaySetup";
import { AddEditWeekOffs } from "pages/configuration/employeeWeekOff/AddEditWeekOffs";        
import ApprovalHierarchy from "pages/configuration/approvalhierarchy/ApprovalHierarchy";
import AddEditApprovalHierarch from "pages/configuration/approvalhierarchy/AddEditApprovalHierarch";
import PayrollPeriod from "pages/configuration/payrollPeriod/PayrollPeriod";
import AddEditPayrollPeriod from "pages/configuration/payrollPeriod/AddEditPayrollPeriod";
import LeaveRequest from "pages/employee/leaveRequest/LeaveRequest";
import ReimbursementsRequest from "pages/employee/reimbursementRequest/ReimbursementRequest";
import AdvanceSalaryRequest from "pages/employee/advanceSalaryRequest/AdvanceSalaryRequest";
import EditCompanyProfile from "pages/admin/organization/EditCompanyProfile";
import PageNotFound from "components/pages/PageNotFound";
import UserGroup from "pages/admin/userGroup/UserGroup";
import AdminNoticeBoard from "pages/admin/noticeBoard/NoticeBoard";
import UserPermission from 'pages/admin/userPermission/UserPermission';
import EditUserPermission from 'pages/admin/userPermission/EditUserPermission';
import PasswordPolicy from 'pages/admin/passwordPolicy/PasswordPolicy';
import AssetRegistry from 'pages/assetManagement/assetRegistry/AssetRegistry';
import AssetEmployeeAudit from 'pages/assetManagement/assetEmployeeAudit/AssetEmployeeAudit';
import AddEditAssetEmployeeAudit from 'pages/assetManagement/assetEmployeeAudit/AddEditAssetEmployeeAudit';
import AddEditAssetRegistry from 'pages/assetManagement/assetRegistry/AddEditAssetRegistry';
import LeaveApproval from 'pages/payroll/approvalInbox/LeaveApproval';
import PayrollAdvanceSalary from 'pages/payroll/approvalInbox/PayrollAdvanceSalary';
import PayrollReimnbursement from 'pages/payroll/approvalInbox/PayrollReimbursements';
import Payroll from 'pages/payroll/Payroll';
import DaywiseAttendance from 'pages/attendance/dayWiseAttendance/DaywiseAttendance';
import Home from 'pages/home/Home';
import Payslip from 'pages/payroll/Payslip';
import AttendanceLogChart from "pages/attendance/attendanceLog/AttendanceLogChart";
import ApprovalInbox from "pages/payroll/approvalInbox/ApprovalInbox";
import PayrollProcess from "pages/payroll/runPayroll/PayrollProcess";
import RunPayrollStep2 from "pages/payroll/runPayroll/RunPayrollStep2";
import DayWiseAttendance from "pages/reports/DayWiseAttendance";
import MonthWiseAttendance from "pages/reports/MonthWiseAttendance";
import SalaryProcessBank from "pages/reports/SalaryProcessBank";
import EmployeeSalarySummary from "pages/reports/EmployeeSalarySummary";
import PayrollDetailedSummary from "pages/reports/PayrollDetailedSummary";
import EmployeeESIStatement from "pages/reports/EmployeeESIStatement";
import EmployeePFStatement from "pages/reports/EmployeePFStatement";
import MusterRoll from "pages/reports/MusterRoll";
import EmployeeEarningsDeduction from "pages/reports/EmployeeEarningsDeduction";
import AttendanceCorrectionReportView from "pages/reports/AttendanceCorrection";
import Subordinates from "pages/reports/Subordinates";
import RosterHome from "pages/roster/RosterHome";
import AddEditRoster from "pages/roster/AddEditRoster";
import RosterReport from "pages/reports/RosterReport";
import SubordinateHome from "pages/employee/subordinates/SubordinateHome";
import SubordinateSubHome from "pages/employee/subordinates/SubordinateSubHome";
import SubordinateSetup from "pages/configuration/subordinatesetup/SubordinateSetup";
import CustomConfiguration from "pages/configuration/customconfiguration/CustomConfiguration";
import SalaryComponent from "pages/configuration/salarycomponent/SalaryComponent";
import SalaryTemplate from "pages/configuration/salaryTemplate/SalaryTemplate";
import SalaryCalculator from "pages/payroll/SalaryCalculator";
import AddEditSalaryTemplate from "pages/configuration/salaryTemplate/AddEditSalaryTemplate";
import AddEditSalaryComponent from "pages/configuration/salarycomponent/AddEditSalaryComponent";
import Resignation from "pages/employee/resignation/Resignation";
import ResignationRequest from "pages/employee/resignation/ResignationRequest";
import StepperIndex from "pages/employee/resignation/StepperIndex";
import PayrollResignation from "pages/payroll/approvalInbox/Resignation"; 
import PayrollResignationRequest from "pages/payroll/approvalInbox/EditResignationApproval";
import LeavePermissionReport from "pages/reports/LeavePermissionReport";
import Documents from "pages/admin/Documents/Documents";
import AddDocuments from "pages/admin/Documents/AddEditDocuments";
import TDSDeclarationForm from "pages/employee/tdsDeclarationForm/TdsDeclarationForm";
import EmployeeOnboardingList from "pages/employee/employeeOnboarding/employeeOnboardingList";
import AddEmployeeOnboardingList from "pages/employee/employeeOnboarding/addEmployeeOnboardingList";
import SubscriptionPlan from "pages/MyPlans";
import UpdatePlan from "pages/changeplan";
import MyplanUpdate from "components/pages/myplanupdate";
import LetterType from "pages/master/LetterType/LetterType";
import AddLetterType from "pages/master/LetterType/AddLetterType";

const queryClient = new QueryClient();
function App() {
  const { checkAuth } = useContext(AuthContext);
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/otpverification" element={<OtpVerification />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/passwordsuccess" element={<PasswordSuccess />} />
          <Route element={<MyLayout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/myplans" element={<SubscriptionPlan />} />
            <Route path="/myplans/updateplan" element={<UpdatePlan />} />

            {/* Admin routes */}
            <Route path="/admin/companyprofile" element={<CompanyProfilePage />} />
            <Route path="/admin/companyprofile/edit" element={<EditCompanyProfile />} />
            <Route path="/admin/usergroup" element={<UserGroup />} />
            <Route path="/admin/passwordpolicy" element={<PasswordPolicy />} />
            <Route path="/admin/userpermission" element={<UserPermission />} />
            <Route path="/admin/userpermission/edit" element={<EditUserPermission />} />
            <Route path="/admin/noticeboard" element={<AdminNoticeBoard />} />
            <Route path="/admin/Documents/add" element={<AddDocuments />} />
            <Route path="/admin/Documents" element={<Documents />} />

            {/* Masters routes */}
            <Route path="/master/employeetype" element={<EmployeeType />} />
            <Route path="/master/employeedepartment" element={<EmployeeDepartemnt />} />
            <Route path="/master/employeedesignation" element={<EmployeeDesignation />} />
            <Route path="/master/reimbursementtype" element={<Reimbursement />} />
            <Route path="/master/employeegroup" element={<EmployeeGroup />} />
            <Route path="/master/assettype" element={<AssetManagement />} />
            <Route path="/master/employeeleavepolicy" element={<EmployeeLeavePolicy />} />
            <Route path="/master/employeeleavepolicy/add" element={<AddEmployeeLeavePolicy />} />
          <Route path="/master/assetcategory" element={<AssetCategory />} />
            <Route path="/master/assetsubcategory" element={<AssetSubCategory />} />
            <Route path="/master/lettertype" element={<LetterType />} />
            <Route path="/master/lettertype/add" element={<AddLetterType />} />

            {/* Employee routes */}
            <Route path="/employee/employeelist" element={<EmployeeList />} />
            <Route path="/employee/employeelist/add" element={<AddEmployeeList />} />
            <Route path="/employee/leaverequest" element={<LeaveRequest />} />
            <Route path="/employee/reimbursements" element={<ReimbursementsRequest />} />
            <Route path="/employee/advancesalary" element={<AdvanceSalaryRequest />} />
            <Route path="/employee/subordinates" element={<SubordinateHome />} />
            <Route path="/employee/subordinates/employeedetails" element={<SubordinateSubHome />} />
            <Route path="/employee/offboarding" element={<Resignation />} />
            <Route path="/employee/offboarding/request" element={<ResignationRequest />} />
            <Route path="/employee/offboarding/Submission" element={<StepperIndex />} />
            <Route path="/employee/tdsdeclaration" element={<TDSDeclarationForm />} />
            <Route path="/employee/employeeonboardinglist" element={<EmployeeOnboardingList />} />
            <Route path="/employee/employeeonboardinglist/add" element={<AddEmployeeOnboardingList />} />
            <Route path="/employee/approvalinbox" element={<ApprovalInbox />} />
            <Route path="/employee/approvalinbox/leaveapproval" element={<LeaveApproval />} />
            <Route path="/employee/approvalinbox/advancesalary" element={<PayrollAdvanceSalary />} />
            <Route path="/employee/approvalinbox/reimnbursement" element={<PayrollReimnbursement />} />

            {/* Assert management routes */}
            <Route path="/assetmanagement/assetregistry" element={<AssetRegistry />} />
            <Route path="/assetmanagement/assetemployeeaudit" element={<AssetEmployeeAudit />} />
            <Route path="/assetmanagement/assetemployeeaudit/add" element={<AddEditAssetEmployeeAudit />} />
            <Route path="/assetmanagement/assetregistry/add" element={<AddEditAssetRegistry />} />

            {/* Configuration routes */}
            <Route path="/configuration/holidaysetup" element={<HolidaySetup />} />
            <Route path="/configuration/holidaysetup/add" element={<AddHolidaySetup />} />
            <Route path="/configuration/employeeshift" element={<EmployeeShift />} />
            <Route path="/configuration/employeeshift/add" element={<AddEmployeeShift />} />
            <Route path="/configuration/employeeweekoff" element={<EmployeeWeekOff />} />
            <Route path="/configuration/employeeweekoff/add" element={<AddEditWeekOffs />} />
            {/* <Route path="/configuration/employeedocument" element={<EmployeeDocument />} /> */}
            <Route path="/configuration/salarydeductions" element={<SalaryDeductions />} />
            <Route path="/configuration/salarydeductions/add" element={<AddEditSalaryDeduction />} />
            <Route path="/configuration/approvalhierarchy" element={<ApprovalHierarchy />} />
            <Route path="/configuration/approvalhierarchy/add" element={<AddEditApprovalHierarch />} />
            <Route path="/configuration/payrollperiod" element={<PayrollPeriod />} />
            <Route path="/configuration/payrollperiod/add" element={<AddEditPayrollPeriod />} />
            <Route path="/configuration/subordinatesetup" element={<SubordinateSetup />} />
            <Route path="/configuration/customconfiguration" element={<CustomConfiguration />} />
            <Route path="/configuration/salarycomponent" element={<SalaryComponent />} />
            <Route path="/configuration/salarycomponent/add" element={<AddEditSalaryComponent />} />
            <Route path="/configuration/salarytemplate" element={<SalaryTemplate />} />
            <Route path="/configuration/salarytemplate/add" element={<AddEditSalaryTemplate />} />

            {/* Attendance routes */}
            <Route path="/attendance/dayattendance" element={<DaywiseAttendance />} />
            <Route path="/attendance/attendancelog" element={<AttendanceLog />} />
            <Route path="/attendance/attendancelog/chart" element={<AttendanceLogChart />} />
            {/* <Route path="/attendance/timesheet" element={<TimesheetApproval />} /> */}

            {/* Payroll routes */}
            <Route path="/payroll/payrolldashboard" element={<Payroll />} />
            <Route path="/payroll/runpayroll" element={<PayrollProcess />} />
            <Route path="/payroll/runpayroll/process" element={<RunPayrollStep2 />} />
            {/* <Route path="/employee/approvalinbox" element={<ApprovalInbox />} />
            <Route path="/employee/approvalinbox/leaveapproval" element={<LeaveApproval />} />
            <Route path="/employee/approvalinbox/advancesalary" element={<PayrollAdvanceSalary />} />
            <Route path="/employee/approvalinbox/reimnbursement" element={<PayrollReimnbursement />} /> */}
            <Route path="/payroll/payslip" element={<Payslip />} />
            <Route path="/payroll/salarycalculator" element={<SalaryCalculator />} />
            <Route path="/employee/approvalinbox/resignationRequest" element={<PayrollResignationRequest />} />
            <Route path="/employee/approvalinbox/resignation" element={<PayrollResignation />} />

            {/* Reports Routes */}
            <Route path="/reports/daywiseattendance" element={<DayWiseAttendance />} />
            <Route path="/reports/monthwiseattendance" element={<MonthWiseAttendance />} />
            <Route path="/reports/salaryprocessbank" element={<SalaryProcessBank />} />
            <Route path="/reports/salarysummary" element={<EmployeeSalarySummary />} />
            <Route path="/reports/payrolldetailedsummary" element={<PayrollDetailedSummary />} />
            <Route path="/reports/esistatement" element={<EmployeeESIStatement />} />
            <Route path="/reports/pfStatement" element={<EmployeePFStatement />} />
            <Route path="/reports/musterRoll" element={<MusterRoll />} />
            <Route path="/reports/earningsdeductions" element={<EmployeeEarningsDeduction />} />
            <Route path="/reports/attendancecorrection" element={<AttendanceCorrectionReportView />} />
            <Route path="/reports/subordinates" element={<Subordinates />} />
            <Route path="/reports/Roster" element={<RosterReport />} />
            <Route path="/reports/LeavePermissionReport" element={<LeavePermissionReport />} />
            {/* Roster Routes */}
            <Route path="/roster" element={<RosterHome />} />
            <Route path="/roster/add" element={<AddEditRoster />} />

            <Route path="*" element={<PageNotFound />} />
            <Route path="/subscribenow" element={<MyplanUpdate />} />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
